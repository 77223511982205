import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import CreditCard from '@material-ui/icons/CreditCard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ServiceStation from '@material-ui/icons/LocalGasStation';

//Scroll to the element 
const GoToElementInView = function (elementToGo) {
    const element = document.getElementById(elementToGo);
    element.scrollIntoView({ behavior: "smooth", offsetTop: -1000, block: "start" });
}

class SecondaryItems extends React.Component {
    state = {
        open: false,
        openSS: false,
    };

    hideMenus = () => {
        this.setState({ open: false, openSS: false });
    }

    handleClick = () => {
        this.props.setState({ open: true });
        this.setState(state => ({ open: !state.open, openSS: state.open ? false : state.open }));
    };

    handleClickSS = () => {
        this.props.setState({ open: true });
        this.setState(state => ({ openSS: !state.openSS, open: state.openSS ? false : state.openSS }));
    };

    render() {
        return (
            <div>
                <ListItem button onClick={this.handleClick}>
                    <ListItemIcon>
                        <CreditCard />
                    </ListItemIcon>
                    <ListItemText inset primary="Transacciones" />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>                        
                        <ListItem button onClick={() => GoToElementInView('transactionRequest')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Datos Solicitud" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('transaction')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Datos Resultado" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('transactionQuery')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Query" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('transactionSandbox')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Sandbox" />
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem button onClick={this.handleClickSS}>
                    <ListItemIcon>
                        <ServiceStation />
                    </ListItemIcon>
                    <ListItemText inset primary="Estaciones de Servicio" />
                    {this.state.openSS ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openSS} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>                        
                        <ListItem button onClick={() => GoToElementInView('serviceStationRequest')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Datos Solicitud" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('serviceStation')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Datos Resultado" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('serviceStationQuery')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Query" />
                        </ListItem>
                        <ListItem button onClick={() => GoToElementInView('serviceStationSandbox')}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText inset primary="Sandbox" />
                        </ListItem>
                    </List>
                </Collapse>
            </div>
        );
    }
}

export default SecondaryItems;