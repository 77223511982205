import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import arrays from '../model/arrays';

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar
});

function MainContent(props) {
    const { classes } = props;
    return (
        <div>
            <div className={classes.appBarSpacer} />
            <Typography variant="h3">API Flotas</Typography>

            <div className={classes.appBarSpacer} id="introduction" />

            <Typography variant="h4" >Introducción</Typography>
            <Typography>
                <br />
                El API le permite obtener la información histórica, actualizada y tiempo real, asociada a los productos de BAC Flota. En esta documentación encontrará las pautas a seguir para poder consumir datos de la Plataforma Bac Flotas por medio de servicios Web.
            </Typography>

            <div className={classes.appBarSpacer} id="overview" />

            <Typography variant="h4" >Visión general</Typography>
            <Typography>
                <br />
                Las APIs ofrecen un medio para obtener la data de la Plataforma Bac Flotas de una forma más directa, la cual le permite una manipulación más personalizada de la misma. <br />                
            </Typography>
            
            <div className={classes.appBarSpacer} id="authentication" />
            <Typography variant="h4" >Autenticación</Typography>
            <Typography>
                <br />
                Para poder utilizar los servicios Web, se debe utilizar un Código Seguro (Token). El mismo es generado desde la Plataforma web de Bac Flotas.
            </Typography>

            <div className={classes.appBarSpacer} id="errorcodes" />
            <Typography variant="h4" >Códigos de Error</Typography>
            <br />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Detalle</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        arrays.errorCodes &&
                        arrays.errorCodes.map(element => {
                            return (<TableRow key={element.key}>
                                <TableCell>{element.value}</TableCell>
                                <TableCell>{element.text}</TableCell>
                            </TableRow>);
                        })

                    }
                </TableBody>
            </Table>

            {/* Temp Remove from web site, pending validate in Azure
            <div className={classes.appBarSpacer} id="ratelimit" />
            <Typography variant="h4" >Límite de Solicitudes</Typography>
            <Typography>
                <br />
                Esta API le permite obtener información asociada a los productos de BAC Flota
            </Typography> */}
        </div>
    );
}

MainContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainContent);