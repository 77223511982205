import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems } from './transactions/listItems';
import SecondaryItems from './secondarylist';
import Cards from '../src/transactions/cards';
import MainContent from '../src/transactions/maincontent';
import SandBox from '../src/transactions/sandbox';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import utilities from './model/utilities';
import arrays from './model/arrays';
import MainContentTables from './mainContentTables'

const drawerWidth = 340;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
    background: 'black',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '90vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.secondaryElementsHide = React.createRef();
  }

  handleDrawerOpen = () => {
    this.setState({ open: true, hideSecundary: null });
  };

  handleDrawerClose = () => {
    this.secondaryElementsHide.current.hideMenus();
    this.setState({ open: false, hideSecundary: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Documentación para el uso de Servicios API
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require('./imgs/Flota.png')}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
            </Card>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>
            <SecondaryItems
              ref={this.secondaryElementsHide}
              setState={p => { this.setState(p) }}
            />
          </List>
        </Drawer>
        <main className={classes.content}>
          <MainContent />
          <br />
          <Divider />
          <br />

          <div className={classes.appBarSpacer} id="transactionRequest" />
          <Typography variant="h4" >Transacciones</Typography>
          <Typography>
            <br />
            Facilita un listado de las transacciones realizadas. Estas se pueden filtrar por fecha, tipo de transacción o a partir de una transacción especifica. <br /><br />
            Datos para realizar la solicitud:
          </Typography>
          <MainContentTables
            table={arrays.transactionRequest}
          />
          <div className={classes.appBarSpacer} id="transaction" />
          <Typography>
            <br />
            Se detalla cada campo que se obtiene en el resultado:
          </Typography>
          <MainContentTables
            table={arrays.transaction}
          />
          <div className={classes.appBarSpacer} id="transactionQuery" />
          <Typography>
            <br />
            Código necesario para establecer comunicación con el API desde diferentes plataformas.
          </Typography>
          <Cards
            url={utilities.transactionCard.url}
            headers={utilities.transactionCard.headers}
            body={utilities.transactionCard.body}
            tabs={utilities.transactionCard.tabs} 
            isTransaction={true}/>
          <br />
          <Divider />
          <br />
          <div className={classes.appBarSpacer} id="transactionSandbox" />
          <Typography variant="h4" id="sandbox">Sandbox</Typography>
          <Typography>
            <br />
            Ejemplo de los datos obtenidos atravez del API. Si lo ejecuta con todos los parámetros vacíos el API enviara un ejemplo con datos ficticios. Una vez que utilice el token y coloque los filtros, se enviaran los datos reales. Los datos se mostraran ordenados por la fecha de inserción.
          </Typography>
          <SandBox IsTransaction={true} />
          <br />
          <Divider />
          <br />

          <div className={classes.appBarSpacer} id="serviceStationRequest" />
          <Typography variant="h4" >Estaciones de servicio</Typography>
          <Typography>
            <br />
            Facilita un listado de las Estaciones de Servicio. <br /><br />
            Datos para realizar la solicitud:
          </Typography>
          <MainContentTables
            table={arrays.serviceStationRequest}
          />
          <div className={classes.appBarSpacer} id="serviceStation" />
          <Typography>
            <br />
            Se detalla cada campo que se obtiene en el resultado:
          </Typography>
          <MainContentTables
            table={arrays.serviceStation}
          />
          <div className={classes.appBarSpacer} id="serviceStationQuery" />
          <Typography>
            <br />
            Código necesario para establecer comunicación con el API desde diferentes plataformas.
          </Typography>
          <Cards
            url={utilities.serviceStationCard.url}
            headers={utilities.serviceStationCard.headers}
            body={utilities.serviceStationCard.body}
            tabs={utilities.serviceStationCard.tabs}
            isTransaction={false} />
          <br />
          <Divider />
          <br />
          <div className={classes.appBarSpacer} id="serviceStationSandbox" />
          <Typography variant="h4" id="sandbox">Sandbox</Typography>
          <Typography>
            <br />
            Ejemplo de los datos obtenidos atravez del API. Si lo ejecuta con todos los parámetros vacíos el API enviara un ejemplo con datos ficticios. Una vez que utilice el token y coloque los filtros, se enviaran los datos reales.
          </Typography>
          <SandBox IsServiceStation={true} />
          <Divider />
          <br />
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);