import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContainers from '../tabcontainer';

const styles = theme => ({
  card: {
    display: 'flex',
  },
  details: {
    width: '30%',
  },
  detailsblack: {
    background: 'black',
    width: '70%',
    fontcolor: 'white',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
    borderradius: '0px',
    boxshadow: 'none',
  },
  table: {
    minWidth: 700,
  },
  tableheader: {
    backgroundColor: 'rgba(52, 52, 52, 0.8)',
  },
  tablebody: {
    backgroundColor: 'rgba(52, 52, 52, 1)',
  },
  fontcolor: {
    color: 'white',
  },
});

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    
    return (
      <div>
        <br />
        <Card className={classes.card}>
          <div className={classes.details}>
            <CardContent>
              <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
                {this.props.isTransaction ? "POST" : "GET" }
              </Typography>
              <Typography component="p">
                <b>{this.props.url}</b>
              </Typography>
              <br />

              <Divider />
              <br />
              <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
                HEADERS
              </Typography>
              <Typography component="p">
                <b>Content-Type:</b>  {this.props.headers}<br />
                <b>Authorization:</b>  Token obtenido desde la plataforma Web (Flotas).
              </Typography>
              <br />

              <Divider />
              <br />
              <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
                BODY
              </Typography>              
              <pre><b>{this.props.body}</b></pre>              
            </CardContent>
          </div>

          <div className={classes.detailsblack}>
            <CardContent title="Ejemplo de Solicitud">
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableheader}>
                    <TableRow>
                      <TableCell className={classes.fontcolor}>
                        <b>{this.props.url}</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tablebody}>
                    <TableRow>
                      <TableCell className={classes.fontcolor}>
                        <Tabs value={value} onChange={this.handleChange}>
                          { 
                            this.props.tabs && 
                            this.props.tabs.map(element => {
                              return ( <Tab key={element.key} label={element.label} /> );
                            })
                          }
                        </Tabs>
                        {
                          this.props.tabs && 
                          this.props.tabs.map(element => {
                            return ( value === element.value && 
                            <TabContainers key={element.key} TextContainer={element.text} /> );
                          })
                        }
                        <br />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

Cards.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Cards);