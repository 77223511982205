
class ConnetToAPI {
    static getData = async (method, token, cb) => {
    
     await fetch(method, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json(); // Obtener el cuerpo de la respuesta como texto
        }).then(async (res) => {
                cb(null, res);
            })
            .catch(async (error) => {
                console.log(`Error ejecutando fetch: ${error}`);
                cb(error, null);
            });
    }

    static sendData = async (method, data, token, cb) => {
     
        await fetch(method, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            timeout: 500,
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res.status === 204) {
                    return res.statusText;
                }
                return res.json();
            })
            .then(async (res) => {
                console.log('Success send data POST');
                cb(null, res);
            })
            .catch((error) => {
                console.log('Error ejecutando fetch' + error);
                cb(error, null);
            });
    }
}
export { ConnetToAPI };