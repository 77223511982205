import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  fontcolor: {
    color: 'white',
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class TabContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  };

  render() {
    const { classes } = this.props;

    return (
      <TabContainer >
        <pre>
          <span className={classes.fontcolor}>{this.props.TextContainer}</span>
        </pre>
      </TabContainer>
    );
  }
}

TabContainers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabContainers);
