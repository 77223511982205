import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ConnetToAPI } from '../bs/transaction'
import CircularProgress from '@material-ui/core/CircularProgress';
import Utilities from '../model/utilities'

const styles = theme => ({
    button: {
        verticalAlign: 'bottom'
    },
    progress: {
        verticalAlign: 'bottom'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        height: 520,
    },
    tokencontainer: {
        width: 635,
    },
    scrollpre: {
        overflow: 'auto',
    }
});

class SandBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null,
            isLoading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitSS = this.handleSubmitSS.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ result: null, isLoading: true });

        var data = {
            startdate: event.target[1].value,
            enddate: event.target[2].value,
            status: event.target[3].value,
            rows: event.target[4].value,
            page: event.target[5].value,
            lasttransactionid: event.target[6].value,
        };
        var token = event.target[0].value;
        var method = token ? Utilities.transaction : Utilities.transactionsandbox;

        ConnetToAPI.sendData(method, data, token, (err, data) => {
            if (!err) {
                this.setState({ result: data ? JSON.stringify(data, null, 2) : '', isLoading: false });
            } else {
                this.setState({ result: err.message, isLoading: false });
            }
        });
    }

    handleSubmitSS(event) {
        event.preventDefault();
        this.setState({ result: null, isLoading: true });

        var token = event.target[0].value;
        var method = token ? Utilities.servicestationUrl : Utilities.servicestationUrlsandbox;

        ConnetToAPI.getData(method, token, (err, data) => {
            if (!err) {
                this.setState({ result: data ? JSON.stringify(data, null, 2) : '', isLoading: false });
            } else {
                this.setState({ result: err.message, isLoading: false });
            }
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                {/* Transactions */}
                {
                    this.props.IsTransaction &&
                    <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                        <div>
                            <TextField
                                id="token"
                                name="token"
                                label="Token Actual"
                                className={classes.tokencontainer}
                                margin="normal"
                            />
                        </div>

                        <TextField
                            id="startdate"
                            name="startdate"
                            label="Fecha Inicial"
                            type="date"
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="enddate"
                            name="enddate"
                            label="Fecha Final"
                            type="date"
                            className={classes.textField}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="status"
                            name="status"
                            label="Estado"
                            className={classes.textField}
                            margin="normal"
                        />
                        <TextField
                            id="rows"
                            name="rows"
                            label="Filas"
                            className={classes.textField}
                            margin="normal"
                        />
                        <TextField
                            id="page"
                            name="page"
                            label="Página"
                            className={classes.textField}
                            margin="normal"
                        />
                        <TextField
                            id="lasttransactionid"
                            name="lasttransactionid"
                            label="Id Última Transacción"
                            className={classes.textField}
                            margin="normal"
                        />
                        <Button variant="outlined" color="primary" className={classes.button} type="submit" >
                            Ejecutar Solicitud
                        </Button>
                        &nbsp;
                        {
                            this.state.isLoading ? <CircularProgress className={classes.progress} /> : null
                        }
                    </form>
                }
                {/* Service Stations */}
                {
                    this.props.IsServiceStation &&
                    <form className={classes.container} noValidate autoComplete="off" onSubmit={this.handleSubmitSS}>
                        <TextField
                            id="token"
                            name="token"
                            label="Token Actual"
                            className={classes.tokencontainer}
                            margin="normal"
                        />
                        &nbsp;
                        <Button variant="outlined" color="primary" className={classes.button} type="submit" >
                            Ejecutar Solicitud
                        </Button>
                        &nbsp;
                        {
                            this.state.isLoading ? <CircularProgress className={classes.progress} /> : null
                        }
                    </form>
                }
                <br />
                <Typography variant="h6" id="introduction">Resultado</Typography>
                <Card className={classes.card}>
                    <div className={classes.details}>
                        <CardContent className={classes.scrollpre}>
                            <pre>
                                {this.state.result}
                            </pre>
                        </CardContent>
                    </div>
                </Card>
            </div>
        );
    }
}

SandBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SandBox);