import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class MainContentTables extends Component {
    render() {
        return (
            <div>
                <br />
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Valor</TableCell>
                            <TableCell>Definición</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.table &&
                            this.props.table.map(row => {
                                return (<TableRow key={row.key}>
                                    <TableCell>{row.value}</TableCell>
                                    <TableCell>{row.text}</TableCell>
                                </TableRow>);
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        );
    }
}

export default MainContentTables;